import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Button } from '@vibrent/electryon';
import { Menu, MenuItem } from '@mui/material';
import * as userActions from '../../actions/userActions';
import * as style from './styles.scss';

class RoleSelector extends Component {
  constructor(props) {
    super(props);

    if (props.currentActiveProgram) {
      this.addRoleAndProgramParam(props);
    }
    this.onChangeRole = this.onChangeRole.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Check if URL change, then add role param to URL
    const currentUrl = prevProps.location.pathname + prevProps.location.search;
    const nextUrl = this.props.location.pathname + this.props.location.search;
    if (prevProps.isPMI !== this.props.isPMI) {
      this.addRoleAndProgramParam(this.props);
    }
    if (currentUrl !== nextUrl && this.props.currentActiveProgram) {
      this.addRoleAndProgramParam(this.props);
    }
  }

  /**
   * Change role and redirect to searchParams link
   * @param {string} roleName
   */
  onChangeRole(roleName) {
    const newRole = _.find(this.props.currentActiveProgram.roles, r => roleName === r.name);
    if (_.isFunction(window.onBeforeRoleChange)) {
      window.onBeforeRoleChange((willChangeRole) => {
        if (willChangeRole) {
          this.props.actions.setUserRole(newRole);
          this.props.actions.resetSearchState();
        }
      });
    } else if (_.isNil(window.onBeforeRoleChange)) {
      this.props.actions.setUserRole(newRole);
      this.props.actions.resetSearchState();
    }
    const searchParams = new URLSearchParams();
    searchParams.append('role', newRole.name);
    this.props.history.push(`/?${searchParams.toString()}`);
  }

  /**
   *  Add role and program param to URL if not exist
   *  Don't add program if its 'ALL OF US' program
   *  ToDo: Use program id instead of program name.
   * @param {Object} props
   */
  addRoleAndProgramParam(props) {
    const { isPMI } = props;
    const search = new URLSearchParams(props.location.search);
    const roleURl = search.get('role');
    const redirect = search.get('redirect');
    if (!isPMI) {
      const programUrl = search.get('program');
      if (programUrl && props.currentActiveProgram) {
        if (!(props.currentActiveProgram.name === programUrl.toString())) {
          this.props.history.replace('/accessDenied');
        }
      }
      if (!roleURl && !redirect && !programUrl) {
        search.delete('role');
        search.delete('program');
        search.append('program', this.props.currentActiveProgram.name);
        search.append('role', props.roleSelected.name);
        this.props.history.replace(`${props.location.pathname}?${search.toString()}`, props.location.state);
      }
      if (roleURl && props.roleSelected.name !== roleURl) {
        const newRole = _.find(props.currentActiveProgram.roles, r => roleURl === r.name);
        if (newRole) {
          this.props.actions.setUserRole(newRole);
        }
      }
    } else {
      const userLocale = search.get('locale');
      if (!roleURl && !redirect && !userLocale) {
        search.delete('role');
        search.delete('locale');
        search.append('role', props.roleSelected.name);
        search.append('locale', props.userDetail.preferredLanguage.locale);
        this.props.history.replace(`${props.location.pathname}?${search.toString()}`, props.location.state);
      }
      if (roleURl && props.roleSelected.name !== roleURl) {
        const newRole = _.find(props.currentActiveProgram.roles, r => roleURl === r.name);
        search.delete('locale');
        search.append('locale', props.userDetail.preferredLanguage.locale);
        this.props.history.replace(`${props.location.pathname}?${search.toString()}`, props.location.state);
        if (newRole) {
          this.props.actions.setUserRole(newRole);
        }
      }
    }
  }

  render() {
    const { currentActiveProgram, roleSelected, t } = this.props;
    if (currentActiveProgram && currentActiveProgram.roles.length > 1) {
      const roles = currentActiveProgram.roles;
      const menuItems = _.map(_.orderBy(roles, 'displayName'), role => (
        <MenuItem
          id={role.name}
          aria-label={role.name}
          className="manager-account-menu-item"
          onClick={() => this.onChangeRole(role.name)}
        >
          {t(role.displayName)}
        </MenuItem>
      ));
      return (
        <Button
          buttonType="dropdown"
          className="manager-account"
          title={roleSelected ? t(roleSelected.displayName) : ''}
          variant="text"
          color="text"
        >
          <Menu>
            {menuItems}
          </Menu>
        </Button>
      );
    } else if (currentActiveProgram) {
      return <div id="single-role" className={style['single-role']}>{t(roleSelected.displayName)}</div>;
    }
    return null;
  }
}

const mapStateToProps = state => ({
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  roleSelected: state.UserDetail.get('roleSelected'),
  isPMI: state.UserDetail.get('isPMI'),
  userDetail: state.UserDetail.get('userDetail'),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, userActions), dispatch),
});

RoleSelector.propTypes = {
  actions: PropTypes.shape({
    setUserRole: PropTypes.func.isRequired,
    resetSearchState: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  currentActiveProgram: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape()),
    permissions: PropTypes.arrayOf(PropTypes.shape()),
    name: PropTypes.string,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  roleSelected: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }),
  isPMI: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  userDetail: PropTypes.shape({
    preferredLanguage: PropTypes.shape({
      locale: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

RoleSelector.defaultProps = {
  actions: {},
  currentActiveProgram: null,
  roleSelected: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleSelector);

export const RoleSelectorComponent = RoleSelector;
