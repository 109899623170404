import React, { Component, Fragment } from 'react';
import { Map as MapImmutable } from 'immutable';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navbar, Nav, Tooltip,
  OverlayTrigger } from 'react-bootstrap';
import { Button } from '@vibrent/electryon';
import { Divider, Menu, MenuItem } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faUsersGear, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import moment from 'moment';
import * as style from './styles.scss';
import * as userActions from '../../actions/userActions';
import * as SessionRefresherActions from '../sessionRefresher/actions';
import NotificationsModal from '../notificationsModal';
import RoleSelector from '../roleSelector';
import LogoHolder from '../common/logoHolder';
import lmsIcon from '../../images/lms.png';
import { VIEW_MODE } from '../../constants';

// declare Navbar child
const {
  Header: HeaderNavbar,
  Brand: BrandNavbar,
  Toggle: ToggleNavbar,
  Collapse: CollapseNavbar,
} = Navbar;

const infoIcon = <FontAwesomeIcon icon={faInfoCircle} className="navbar-icon menu-item icon" />;
const partnerCMSIcon = <FontAwesomeIcon icon={faUsersGear} className="navbar-icon menu-item users-gear-icon icon" />;
const studyDesignerIcon = <FontAwesomeIcon icon="fa-solid fa-box-open-full" className="navbar-icon menu-item box-open-full-icon icon" />;
const gridIcon = <FontAwesomeIcon icon="fa-solid fa-grid" className="navbar-icon grid-icon" />;
const userCog = <FontAwesomeIcon icon="fa-solid fa-user-gear" className="navbar-icon grid-icon" />;
const profileIcon = <FontAwesomeIcon icon="fa-solid fa-user-circle" className="navbar-icon menu-item icon" />;

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}
class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.onSelectMenu = this.onSelectMenu.bind(this);
    this.onChangeLanguage = this.onChangeLanguage.bind(this);
  }

  componentDidMount() {
    const { currentActiveProgram, actions, CATIRequesting, isPMI } = this.props;
    const catiPermission = currentActiveProgram.permissions &&
      currentActiveProgram.permissions.find(permission => permission.resource === '/api/cati/login');
    const activeCatiSession = window.localStorage.getItem('activeCatiSession');
    if (!_.isEmpty(catiPermission) && activeCatiSession && !CATIRequesting && !isPMI) {
      actions.getCatiSession();
    }
  }

  componentDidUpdate() {
    const { currentActiveProgram, isCATIActive, isParticipantProfile } = this.props;
    const catiPermission = currentActiveProgram.permissions &&
      currentActiveProgram.permissions.find(permission => permission.resource === '/api/cati/login');
    if (!_.isEmpty(catiPermission)) {
      if (isCATIActive && !isParticipantProfile) {
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
      } else if ((!isCATIActive && !isParticipantProfile) ||
        (isParticipantProfile && isCATIActive)) {
        document.removeEventListener(visibilityChange, this.handleVisibilityChange);
      }
    }
  }

  /**
   * On Select Menu
   * @param {Object} event
   */
  onSelectMenu(event) {
    switch (event) {
    case '1':
      // Change Password
      window.location.href = this.props.isPMI ?
        `${window.localStorage.getItem('keycloakUrl')}?&kc_locale=${this.props.userDetail.preferredLanguage.locale}` :
        window.localStorage.getItem('keycloakUrl');
      break;
    case '2':
      // Log out
      this.props.history.push('/logout');
      break;
    default:
    }
  }
  onChangeLanguage(locale) {
    this.props.actions.updateUserLanguage(locale);
    this.props.i18n.changeLanguage(locale.language);
    const search = new URLSearchParams(this.props.location.search);
    search.delete('locale');
    search.append('locale', locale.locale);
    this.props.history.replace(`${this.props.location.pathname}?${search.toString()}`, this.props.location.state);
    window.localStorage.setItem('userLocale', locale.language);
  }

  onChangeProgram = (programName) => {
    const { userDetail, actions, history, serverTimestamp } = this.props;
    const currentProgram = _.find(userDetail.assignProgramList, p => programName === p.name);
    actions.programSwitch(currentProgram.id)
      .then((response) => {
        if (response.assignProgramList) {
          window.sessionStorage.clear();
          this.props.actions.storeUserDetail(response);
          this.props.actions.resetSelectedSite();
          this.props.actions.resetSelectedMultiSite();
          this.props.actions.setSelectedDate(serverTimestamp);
          this.props.actions.changeViewMode(VIEW_MODE.DAY);
          this.props.actions.resetSearchState();
          this.props.actions.getHelpCentreDetails(userDetail.jwtToken);
          history.push(`/login${this.props.location.search}`);
        }
      });
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.props.actions.colapseNavbar(true);
    this.setState({
      anchorEl: null,
    });
  };

  handleVisibilityChange = () => {
    if (!document[hidden]) {
      const { actions, CATIRequesting, isCATIActive } = this.props;
      if (!CATIRequesting && isCATIActive) {
        actions.getCatiSession();
      }
    }
  }

  showHelpCenter = () => {
    const { showHelpCentre, helpCentreUrl, t } = this.props;
    if (showHelpCentre) {
      return (
        <MenuItem
          className={style['banner-menu-item']}
          component="a"
          href={helpCentreUrl}
          target="_blank"
          rel="noopener"
          aria-label="Help Center"
          onClick={() => this.handleClose()}
        >
          <div>
            {infoIcon}
            <div className="text-container">
              <b>Help Center</b>
              {t('The essential guide')}
            </div>
          </div>
        </MenuItem>
      );
    }
    return null;
  }

  showLMS = () => {
    const { lmsPortalUrl, lmsStatus, t } = this.props;
    if (lmsStatus) {
      return (
        <MenuItem
          className={style['banner-menu-item']}
          component="a"
          target="_blank"
          href={lmsPortalUrl}
          rel="noopener"
          aria-label="Vibrent Research Academy"
        >
          <div>
            <img src={lmsIcon} alt="lms" className="navbar-icon menu-item" />
            <div className="text-container">
              <b>Vibrent Research Academy</b>
              {t('A learning community')}
            </div>
          </div>
        </MenuItem>
      );
    }
    return null;
  }
  showStudyDesigner = () => {
    const { studyDesignStatus, studyDesignUrl, t } = this.props;

    if (studyDesignStatus) {
      return (
        <MenuItem
          className={style['banner-menu-item']}
          component="a"
          target="_blank"
          href={studyDesignUrl}
          rel="noopener"
          aria-label="Study Designer"
        >
          <div>
            {studyDesignerIcon}
            <div className="text-container">
              <b>Study Designer</b>
              {t('Research Study solution')}
            </div>
          </div>
        </MenuItem>
      );
    }
    return null;
  }
  showPartnerCMS = () => {
    const { partnerCmsStatus, partnerCmsUrl, t } = this.props;

    if (partnerCmsStatus) {
      return (
        <MenuItem
          className={style['banner-menu-item']}
          component="a"
          target="_blank"
          href={partnerCmsUrl}
          rel="noopener"
          aria-label={t('Partner CMS')}
        >
          <div>
            {partnerCMSIcon}
            <div className="text-container">
              <b>{t('Partner CMS')}</b>
              {t('Platform Management')}
            </div>
          </div>
        </MenuItem>
      );
    }
    return null;
  }
  renderMyProfile = () => {
    const { t } = this.props;
    const handleProfile = () => {
      if (!this.props.isPMI) {
        this.props.actions.colapseNavbar(true);
      }
      this.handleClose();
      this.props.history.push('/profile');
    };
    return (
      <MenuItem
        className={style['banner-menu-item']}
        onClick={() => handleProfile()}
        aria-label="Profile"
      >
        <div>
          {profileIcon}
          <div className="text-container">
            <b>{t('My Profile')}</b>
            {t('View profile information.')}
          </div>
        </div>
      </MenuItem>
    );
  }
  renderLanguageSwitcher = () => {
    const { supportedLanguages } = this.props.currentActiveProgram;
    const { preferredLanguage } = this.props.userDetail;
    const languages = supportedLanguages.map(lng => (
      <MenuItem
        id={lng.language}
        aria-label={lng.language}
        onClick={() => this.onChangeLanguage(lng)}
      >
        {lng.name}
      </MenuItem>
    ));
    const userPreferedLanguage = supportedLanguages.filter(lng => lng.language ===
      preferredLanguage.language);
    return (
      <Fragment>
        <span className="vertical-rule" />
        <Button
          buttonType="dropdown"
          className="language-switcher"
          aria-label={userPreferedLanguage[0].name}
          title={userPreferedLanguage[0].name}
          label={(
            <Fragment>
              <i className="fa-regular language-icon fa-globe" />
              {userPreferedLanguage[0].name}
            </Fragment>
          )}
          variant="text"
          color="text"
        >
          <Menu>
            {languages}
          </Menu>
        </Button>
      </Fragment>
    );
  }
  renderProgramSwitcher = () => {
    const { userDetail, currentActiveProgram, isPMI, isCATIActive, t } = this.props;
    const { alternateText, programLogo } = currentActiveProgram || {};
    if (!isPMI && !_.isEmpty(userDetail)) {
      const { assignProgramList } = userDetail;
      const selectedProgram = programLogo && alternateText ?
        <img src={programLogo} alt={alternateText} height="32" />
        : <div className="program-name">{currentActiveProgram.displayName}</div>;
      if (assignProgramList.length === 1) {
        return (<span className="single-program">
          {selectedProgram}
        </span>);
      }
      const menuItems = assignProgramList.filter(program =>
        currentActiveProgram.programId !== program.id)
        .map(program => (
          <MenuItem
            id={program.name}
            aria-label={program.name}
            onClick={() => this.onChangeProgram(program.name)}
          >
            {program.displayName}
          </MenuItem>
        ));

      const programSwitcher = (
        <span className="d-inline-block">
          <Button
            buttonType="dropdown"
            variant="text"
            color="text"
            aria-label={t('Program Select')}
            className={isCATIActive && 'disabled-program-name'}
            disabled={isCATIActive}
            title={currentActiveProgram.displayName}
            label={selectedProgram}
          >
            <Menu className="program-dropdown">
              {menuItems}
            </Menu>
          </Button>
        </span>
      );
      return isCATIActive ?
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="program-switch" className={style.tooltip}>
            Program switching is disabled during active CATI sessions.
          </Tooltip>}
        >
          {programSwitcher}
        </OverlayTrigger> : programSwitcher;
    }
    return null;
  }

  /**
   * render
   * @return {Element} React element
   */
  render() {
    // Get user name
    const {
      lmsStatus,
      showHelpCentre,
      studyDesignStatus,
      partnerCmsStatus,
      t,
    } = this.props;
    const userName =
      this.props.userDetail && (this.props.userDetail.fullname || this.props.userDetail.username);
    const isShowDropdownLink = lmsStatus || showHelpCentre || studyDesignStatus || partnerCmsStatus;
    const open = Boolean(this.state.anchorEl);
    return (
      <div className={style['banner-mc']}>
        <Navbar collapseOnSelect>
          <HeaderNavbar>
            <BrandNavbar>
              <div>
                {!this.props.userDetail ? <LogoHolder linkedLogo /> : (
                  <LogoHolder
                    linkedLogo
                    isPMI={this.props.isPMI}
                  />
                )}
              </div>
            </BrandNavbar>
            <ToggleNavbar />
          </HeaderNavbar>
          <CollapseNavbar>
            <Nav pullRight onSelect={this.onSelectMenu}>
              {this.props.userDetail ? (
                <Fragment>
                  {this.renderProgramSwitcher()}
                  <NotificationsModal {...this.props} />
                  <span className="vertical-rule" />
                  {this.props.isPMI && <Button
                    buttonType="dropdown"
                    variant="text"
                    color="text"
                    className="user-panel"
                    label={(
                      <Fragment>
                        <i className="user-icon fa fa-user-circle" />
                        <span className="welcome-message">
                          {t('Hi')}, {userName}
                        </span>
                      </Fragment>
                    )}
                    title={`${t('Hi')}, ${userName}`}
                  >
                    <Menu>
                      <MenuItem aria-label={t('Change Password')} onClick={() => this.onSelectMenu('1')}>{t('Change Password')}</MenuItem>
                      <MenuItem aria-label={t('Log out')} onClick={() => this.onSelectMenu('2')}>{t('Log out')}</MenuItem>
                    </Menu>
                  </Button>}
                  <RoleSelector {...this.props} />
                  {this.props.isPMI && this.renderLanguageSwitcher()}
                  {(this.props.isPMI && isShowDropdownLink) ? (
                    <Fragment>
                      <span className="vertical-rule" />
                      <Button
                        className="navitem"
                        buttonType="dropdown"
                        variant="text"
                        color="text"
                        label={gridIcon}
                        title={t('Tools and Support')}
                        aria-label={t('Tools and Support')}
                        endIcon={!this.props.isPMI ? null : <FontAwesomeIcon size="xs" icon={faCaretDown} />}
                      >
                        <Menu className="help-icon-dropdown">
                          {this.showStudyDesigner()}
                          {this.showPartnerCMS()}
                          {this.showHelpCenter()}
                          {this.showLMS()}
                        </Menu>
                      </Button>
                    </Fragment>
                  ) : null}
                  {!this.props.isPMI ? <Fragment>
                    <span className="vertical-rule" />
                    <Button
                      className="navitem"
                      buttonType="dropdown"
                      variant="text"
                      color="text"
                      label={userCog}
                      title={t('Settings')}
                      aria-label={t('Settings')}
                      endIcon={!this.props.isPMI ? null : <FontAwesomeIcon size="xs" icon={faCaretDown} />}
                      onClick={e => this.handleClick(e)}
                    >
                      <Menu
                        className="help-icon-dropdown"
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={this.state.anchorEl}
                        open={open}
                        onClose={this.handleClose}
                      >
                        {this.renderMyProfile()}
                        {this.showHelpCenter()}
                        <Divider />
                        <MenuItem
                          style={{ paddingLeft: '56px', color: '#279cd2' }}
                          aria-label={t('Log out')}
                          onClick={() => { this.onSelectMenu('2'); this.handleClose(); }}
                        >{t('Log out')}
                        </MenuItem>
                      </Menu>
                    </Button>
                  </Fragment> : null}
                </Fragment>
              ) : null}
            </Nav>
          </CollapseNavbar>
        </Navbar>
      </div>
    );
  }
}

Banner.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    replace: PropTypes.func.isRequired,
  }).isRequired,
  userDetail: PropTypes.shape({
    username: PropTypes.string,
    jwtToken: PropTypes.string,
    fullname: PropTypes.string,
    createdAt: PropTypes.string,
    preferredLanguage: PropTypes.string,
    canSwitchViewMode: PropTypes.bool,
    assignProgramList: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      displayName: PropTypes.string,
    })),
  }).isRequired,
  currentActiveProgram: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape()),
    permissions: PropTypes.arrayOf(PropTypes.shape()),
    displayName: PropTypes.string,
    programId: PropTypes.number,
    alternateText: PropTypes.string,
    programLogo: PropTypes.string,
    supportedLanguages: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      lng: PropTypes.string,
      locale: PropTypes.string,
    }).isRequired),
  }),
  actions: PropTypes.shape({
    programSwitch: PropTypes.func,
    storeUserDetail: PropTypes.func,
    resetSelectedSite: PropTypes.func,
    resetSelectedMultiSite: PropTypes.func,
    getCatiSession: PropTypes.func,
    getCATIActiveSessionsSuccess: PropTypes.func,
    updateUserLanguage: PropTypes.func,
    setSelectedDate: PropTypes.func,
    changeViewMode: PropTypes.func,
    resetSearchState: PropTypes.func,
    getHelpCentreDetails: PropTypes.func,
    colapseNavbar: PropTypes.func,
  }),
  isPMI: PropTypes.bool.isRequired,
  modules: PropTypes.shape({
    entrySeq: PropTypes.func.isRequired,
  }).isRequired,
  showHelpCentre: PropTypes.bool,
  helpCentreUrl: PropTypes.string,
  lmsStatus: PropTypes.bool,
  lmsPortalUrl: PropTypes.string,
  partnerCmsStatus: PropTypes.bool,
  partnerCmsUrl: PropTypes.string,
  studyDesignStatus: PropTypes.bool,
  studyDesignUrl: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
    state: PropTypes.shape({}),
  }).isRequired,
  isCATIActive: PropTypes.bool.isRequired,
  CATIRequesting: PropTypes.bool.isRequired,
  isParticipantProfile: PropTypes.bool.isRequired,
  i18n: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  serverTimestamp: PropTypes.instanceOf(moment),
};

Banner.defaultProps = {
  userDetail: {
    assignProgramList: [],
    preferredLanguage: 'en',
  },
  currentActiveProgram: null,
  modules: new MapImmutable({}),
  showHelpCentre: false,
  helpCentreUrl: '',
  lmsStatus: false,
  lmsPortalUrl: '',
  partnerCmsStatus: false,
  partnerCmsUrl: '',
  studyDesignStatus: false,
  studyDesignUrl: '',
  actions: {},
  location: {},
  isCATIActive: false,
  CATIRequesting: false,
  isParticipantProfile: false,
  serverTimestamp: null,
};

const mapStateToProps = state => ({
  userDetail: state.UserDetail.get('userDetail'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  modules: state.modules,
  showHelpCentre: state.UserDetail.get('showHelpCentre'),
  helpCentreUrl: state.UserDetail.get('helpCentreUrl'),
  lmsStatus: state.UserDetail.get('lmsStatus'),
  lmsPortalUrl: state.UserDetail.get('lmsPortalUrl'),
  partnerCmsStatus: state.UserDetail.get('partnerCmsStatus'),
  partnerCmsUrl: state.UserDetail.get('partnerCmsUrl'),
  studyDesignStatus: state.UserDetail.get('studyDesignStatus'),
  studyDesignUrl: state.UserDetail.get('studyDesignUrl'),
  isPMI: state.UserDetail.get('isPMI'),
  isCATIActive: state.SessionRefresherReducer.get('isCATIActive'),
  CATIRequesting: state.SessionRefresherReducer.get('requesting'),
  isParticipantProfile: state.SessionRefresherReducer.get('isParticipantProfile'),
  serverTimestamp: state.CalendarReducer.get('serverTimestamp'),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Object.assign({}, userActions, SessionRefresherActions), dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Banner));

export const BannerClass = Banner;
