import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en.json';
import es from './locales/es.json';

const resources = {
  en: {
    translation: { ...en },
  },
  es: {
    translation: { ...es },
  },
};

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'en',
    ns: 'translation',
    fallbackLng: 'en',
    keySeparator: '|',
    nsSeparator: '|',
    defaultNS: ['translation'],
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    resources,
  });

export default i18n;
