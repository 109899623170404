/* eslint-disable react/jsx-no-target-blank */
import React, { Component, Fragment } from 'react';
import { Map as MapImmutable } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tippy from '@tippyjs/react';
import { Nav, NavItem } from 'react-bootstrap';
import _ from 'lodash';
import * as style from './styles.scss';
import i18n from '../../../../i18n';
import getRouterKey from '../common/utils';

/**
 * NavBar
 * @extends Component
 */
class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handleNavExpand = this.handleNavExpand.bind(this);
    this.handleNavCollapse = this.handleNavCollapse.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (this.state.open && this.props.collapseNavbar !== prevProps.collapseNavbar) {
      this.handleNavCollapse();
    }
  }
  componentWillUnmount() {
    document.body.classList.remove('modal-open');
  }

  /**
   * Get NavItems based on the role that was selected
   * @param {String} activeKey The current active key
   * @return {Element} HTML element
   */
  getNavItems(activeKey) {
    const tooltipStyle = {
      visibility: this.state.open ? 'hidden' : null,
    };
    const labelStyle = {
      visibility: this.state.open ? 'visible' : 'hidden',
      opacity: this.state.open ? 1 : 0,
    };
    let items = [];
    // push all public module
    this.props.modules.entrySeq().forEach((item) => {
      Object.keys(item[1].routes.pmiRoutes).forEach((key) => {
        const router = item[1].routes.pmiRoutes[key];
        // Check if permissions
        if (!item[1].routes.pmiRoutes[key].hidden &&
          (!router.permissions || router.permissions.length === 0)) {
          // Then push WrapperView component
          const pageName = item[1].routes.pmiRoutes[key].label;
          const path = item[1].routes.pmiRoutes[key].path;
          const iconName = item[1].routes.pmiRoutes[key].icon;
          const index = item[1].routes.pmiRoutes[key].index;
          items.push({ id: pageName, href: path, icon: iconName, label: pageName, index });
        }
      });
    });
    const { userDetail, currentActiveProgram, lmsStatus, lmsPortalUrl, partnerCmsStatus,
      partnerCmsUrl, isPMI } = this.props;
    if (userDetail && currentActiveProgram) {
      items = [];
      const { roleSelected } = this.props;
      const userPermissions =
        _.map(_.filter(currentActiveProgram.permissions, p =>
          (p.action === 'view' && p.roleName === roleSelected.name)), p => p.resource);
      const routerKey = getRouterKey(userDetail);
      this.props.modules.entrySeq().forEach((item) => {
        Object.keys(item[1].routes[routerKey]).forEach((key) => {
          const router = item[1].routes[routerKey][key];
          let havePermission = false;

          if (router.permissions && router.permissions.length > 0) {
            const permissionIntersection = _.intersection(router.permissions, userPermissions);
            havePermission = permissionIntersection.length > 0;
          } else {
            havePermission = true;
          }
          if (!item[1].routes[routerKey][key].hidden && havePermission) {
            const pageName = item[1].routes[routerKey][key].label;
            const path = item[1].routes[routerKey][key].path;
            const iconName = item[1].routes[routerKey][key].icon;
            const index = item[1].routes[routerKey][key].index;
            const svg = item[1].routes[routerKey][key].svg;
            items.push({ id: pageName, href: path, icon: iconName, svg, label: pageName, index });
          }
        });
      });
    }
    if (!isPMI) {
      if (lmsStatus) {
        items.push({
          id: 'lms',
          href: lmsPortalUrl,
          icon: 'fa-kit fa-11-training-academy',
          svg: undefined,
          label: 'Training Academy',
          index: 10,
          type: 'outLink',
        });
      }
      if (partnerCmsStatus) {
        items.push({
          id: 'partnerCMS',
          href: partnerCmsUrl,
          icon: 'fa-kit fa-12-community-engagement-builder',
          svg: undefined,
          label: 'Community Engagement Builder',
          index: 11,
          type: 'outLink',
        });
      }
      items.push({
        id: 'Protocol Builder',
        href: '/protocolBuilder',
        icon: 'fa-kit fa-16-pb',
        svg: undefined,
        label: 'Protocol Builder',
        index: 15,
      });
    }
    if (items.length === 0) {
      return null;
    }

    const navItems = _.orderBy(items, 'index').map((item) => {
      if (_.isNil(item.type)) {
        return (
          <NavItem
            id={`icon-${item.label.match(/[^\s/]+/)}`}
            key={`icon-${item.label.match(/[^\s/]+/)}`}
            eventKey={item.href}
            onClick={this.handleNavCollapse}
            active={item.href === activeKey}
            className={`${item.index > 0 ? '' : 'hidden'} ${item.svg ? 'svg-wrapper' : ''}`}
          >
            <Tippy
              content={
                <span
                  className={style['nav-tooltip']}
                  style={tooltipStyle}
                >
                  {i18n.t(item.label)}
                  <i className={style.arrow} />
                </span>
              }
            >
              <div>
                {item.icon && <div className={style['icon-wrapper']}><i className={`${item.icon} ${style['navbar-icon']}`} /></div>}
                {item.svg && <div className={style['icon-wrapper']}>{item.svg}</div>}
                <p className={style['nav-label']} style={labelStyle}>
                  {i18n.t(item.label)}
                </p>
              </div>
            </Tippy>
          </NavItem>
        );
      }
      return (
        <li id={`icon-${item.label.match(/[^\s/]+/)}`} className={`${item.index > 0 ? '' : 'hidden'} ${item.svg ? 'svg-wrapper' : ''}`}>
          <a onClick={this.handleNavCollapse} target="_blank" href={item.href}>
            <Tippy
              content={
                <span
                  className={style['nav-tooltip']}
                  style={tooltipStyle}
                >
                  {i18n.t(item.label)}
                  <i className={style.arrow} />
                </span>
              }
            >
              <div>
                {item.icon && <div className={style['icon-wrapper']}><i className={`${item.icon} ${style['navbar-icon']}`} /></div>}
                <p className={style['nav-label']} style={labelStyle}>
                  {i18n.t(item.label)}
                </p>
              </div>
            </Tippy>
          </a>
        </li>
      );
    });
    const navToggle = (
      <li id="navbar-toggle" className={style['navbar-toggle']} style={{ backgroundColor: this.state.open ? '#ddf1fb' : null }}>
        <a onClick={this.handleNavExpand} role="presentation">
          <Tippy
            content={
              <span
                className={style['nav-tooltip']}
                style={tooltipStyle}
              >
                {i18n.t('Expand Menu')}
                <i className={style.arrow} />
              </span>
            }
          >
            <div>
              <i className={this.props.userDetail.pmiplatform ?
                `fas fa-bars ${style['navbar-icon']}` : `fa-kit fa-01-menu ${style['navbar-icon']}`}
              />
              {!this.props.userDetail.pmiplatform && <p className={style['nav-label']} style={labelStyle}>
                {i18n.t('Menu')}
              </p>}
            </div>
          </Tippy>
        </a>
      </li>
    );
    navItems.unshift(navToggle);
    if (!isPMI) {
      const hosIndex = navItems.findIndex(item => item.props.eventKey === '/hierarchy');
      const hrPosition = hosIndex === -1 ? navItems.findIndex(item =>
        item.props.eventKey === '/protocolBuilder') : hosIndex;
      const hrTag = <hr width={this.state.open ? '264px' : '24px'} />;
      navItems.splice(hrPosition, 0, hrTag);
    }
    return navItems;
  }
  /**
   * handleSelect
   * @param {String} path
   */
  handleSelect(path) {
    this.props.history.push(path);
  }
  handleNavExpand() {
    this.setState({ open: !this.state.open });
    document.body.classList.add('modal-open');
    if (this.state.open) {
      document.body.classList.remove('modal-open');
    }
  }
  handleNavCollapse() {
    this.setState({ open: false });
    document.body.classList.remove('modal-open');
  }
  /**
   * render
   * @return {Element} React element
   */
  render() {
    const activeKey = `/${this.props.history.location.pathname.split('/')[1]}`;
    const navItems = this.getNavItems(activeKey);
    const navStyle = {
      minWidth: this.state.open ? '288px' : '48px',
      top: '48px',
    };
    const lightboxStyle = {
      visibility: this.state.open ? 'visible' : 'hidden',
      opacity: this.state.open ? 1 : 0,
      left: this.state.open ? '288px' : '48px',
    };
    return (
      <Fragment>
        <div style={{ width: '48px' }} />
        <div
          id="leftMenu"
          className={this.props.userDetail.pmiplatform ? style.leftMenu : style['leftMenu-rc']}
          style={navStyle}
        >
          <nav className={style.sidebar}>
            <Nav
              className={this.props.userDetail.pmiplatform ? style['navbar-nav'] : style['navbar-nav-rc']}
              activeKey={activeKey}
              onSelect={this.handleSelect}
            >
              {navItems}
            </Nav>
          </nav>
        </div>
        <div id="lightbox" className={style.lightbox} onClick={this.handleNavExpand} style={lightboxStyle} role="presentation" />
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  roleSelected: state.UserDetail.get('roleSelected'),
  modules: state.modules,
  userDetail: state.UserDetail.get('userDetail'),
  lmsStatus: state.UserDetail.get('lmsStatus'),
  lmsPortalUrl: state.UserDetail.get('lmsPortalUrl'),
  partnerCmsStatus: state.UserDetail.get('partnerCmsStatus'),
  partnerCmsUrl: state.UserDetail.get('partnerCmsUrl'),
  collapseNavbar: state.UserDetail.get('collapseNavbar'),
  isPMI: state.UserDetail.get('isPMI'),
});
export default connect(
  mapStateToProps,
  null,
)(NavBar);
NavBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
  modules: PropTypes.shape({
    entrySeq: PropTypes.func.isRequired,
  }).isRequired,
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
  roleSelected: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  userDetail: PropTypes.shape({
    pmiplatform: PropTypes.bool,
  }).isRequired,
  lmsStatus: PropTypes.bool.isRequired,
  lmsPortalUrl: PropTypes.string.isRequired,
  partnerCmsStatus: PropTypes.bool.isRequired,
  partnerCmsUrl: PropTypes.string.isRequired,
  isPMI: PropTypes.bool.isRequired,
  collapseNavbar: PropTypes.bool.isRequired,
};
NavBar.defaultProps = {
  modules: new MapImmutable({}),
  collapseNavbar: false,
};
